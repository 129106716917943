import React, {useEffect, useRef, useState} from 'react'
import styles from './CollapseItem.module.sass'
import { motion } from "framer-motion"

function CollapseItem({ title, text, pos }) {
  const [isActive, setIsActive] = useState(false)
  const [textHeight, setTextHeight] = useState(0)
  const textRef = useRef()

  const classes = {
    item: styles.collapseItem,
      // ? `${styles.collapseItem} ${styles.collapseItem_active}`
      // : styles.collapseItem,

    close: isActive
      ? `${styles.closeIcon} ${styles.closeIcon_active}`
      : styles.closeIcon,
  }

  const handleClick = () => setIsActive(prev => !prev)

  const variants = {
    hidden: {
      y: 65,
      opacity: 0
    },
    show: {
      y: 0,
      opacity: 1,
    }
  }

  useEffect(() => {
    setTextHeight(textRef.current.clientHeight + 5)
    window.addEventListener('resize', () => { setTextHeight(textRef.current.clientHeight + 5) })
  }, [])

  return (
    <motion.div
      style={
        isActive
        ? { height: textHeight }
        : null
      }
      variants={variants}
      initial="hidden"
      whileInView="show"
      onClick={handleClick} className={classes.item}>
      <div className={styles.heading}>
        <h3 className={styles.title}>{ title }</h3>
        <div className={classes.close} />
      </div>

      <div ref={textRef} className={styles.text}>
        { text.map((t, index) => <p key={Math.random + index}>{ t }</p>) }
      </div>
    </motion.div>
  )
}

export default CollapseItem
