import React, { useContext } from "react"
import styles from "./MobileMenu.module.sass"
import AppContext from "../../context/AppContext"
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher"
import Navigation from "../Navigation/Navigation"
import Button from "../Button/Button"
import ButtonWhite from "../Button/ButtonWhite"
import { ROUTES } from "../../utils/ROUTES"
import { useNavigate } from "react-router-dom"

function MobileMenu() {
  const { isBurgerActive, setIsBurgerActive, t } = useContext(AppContext)

  const classes = {
    container: isBurgerActive
      ? `${styles.menuContainer} ${styles.menuContainer_active}`
      : styles.menuContainer,
  }

  const navigate = useNavigate()

  const handleRegisterClick = () => {
    // navigate(ROUTES.REGISTER)
    window.location.href = "https://clients.zumzak.ru"
    setIsBurgerActive((prev) => !prev)
  }
  const handleLoginClick = () => {
    // navigate(ROUTES.LOGIN)
    window.location.href = "https://clients.zumzak.ru"
    setIsBurgerActive((prev) => !prev)
  }

  return (
    <section className={classes.container}>
      <div className={styles.language}>
        <LanguageSwitcher />
      </div>

      <div className={styles.wrapper}>
        <div className={styles.nav}>
          <Navigation />
        </div>

        <div className={styles.buttons}>
          <ButtonWhite
            click={handleRegisterClick}
            text={t("buttons.getStarted")}
          />
          <Button click={handleLoginClick} text={t("buttons.login")} />
        </div>
      </div>
    </section>
  )
}

export default MobileMenu
