import React from 'react'
import styles from './Waves.module.sass'
import bigWave from '../../images/wave.png'
import smallWave from '../../images/wave-small.png'
function Waves() {
  return (
    <div className={styles.container}>
      {/*<img className={styles.smallWave} src={smallWave} alt="Wave"/>*/}
      <img className={styles.bigWave} src={bigWave} alt="Wave"/>
    </div>
  )
}

export default Waves
