import React from 'react'
import styles from './CardLarge.module.sass'
import CardHeading from "../CardHeading/CardHeading"
import { motion } from "framer-motion"
import rocketImg from '../../../images/reasons/rocket.png'
import Number from "../../Number/Number"

function  CardLarge(
  {
    img,
    number,
    title,
    text = [],
    start = 1,
    end = 4,
    reverse,
    rocket
  }
  ) {

  const cardStyle = {
    gridColumnStart: start,
    gridColumnEnd: end,
  }

  const variants = {
    hidden: {
      opacity: 0
    },

    show: {
      opacity: 1
    }
  }

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      whileInView="show"
      transition={{ duration: 0.3 }}
      style={cardStyle}
      className={reverse ? `${styles.cardLarge} ${styles.cardLarge_reverse}` : styles.cardLarge}>

      {/*{ !reverse && <div className={styles.number}>{ number }</div> }*/}

      <div className={styles.imgContainer}>
        <img className={styles.img} src={img} alt={title}/>

        {
          rocket &&
          <img
            className={styles.rocket}
            src={rocketImg}
            alt="Rocket" />
        }
      </div>




      <div className={styles.column}>


        <div className={styles.texts}>
          <div className={styles.numContainer}>
            <Number number={number} />
          </div>

          <div className={styles.paragraphs}>
            <CardHeading number={number} title={title} />

            { text.length > 0 && text.map((item, index) => <p key={Math.random() + index} className={styles.text}>{ item }</p>) }
          </div>

        </div>

      </div>

      {/*{ reverse && <div className={styles.number}>{ number }</div> }*/}
    </motion.div>
  )
}

export default CardLarge
