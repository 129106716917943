import React, { useContext } from 'react';
import styles from './Logo.module.sass';
import logo from '../../images/main-logo.svg';
import logoEn from '../../images/main-logo-en.svg';
import { motion } from 'framer-motion';
import AppContext from '../../context/AppContext';

function Logo() {
  const { t } = useContext(AppContext);

  const variants = {
    hidden: {
      opacity: 0,
      // y: -50,
    },

    show: {
      opacity: 1,
      // y: -18,
    },
  };

  return (
    <motion.img
      style={{ width: '111px', height: '41px' }}
      variants={variants}
      initial="hidden"
      whileInView="show"
      transition={{ duration: 0.3 }}
      src={t('logoImage.mainLogo') === '../../images/main-logo.svg' ? logo : logoEn}
      className={styles.logo}
      alt="logo"
    />
  );
}

export default Logo;
