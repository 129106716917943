import React, { useContext } from "react"
import styles from "./Footer.module.sass"
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher"
import Logo from "../Logo/Logo"
import Navigation from "../Navigation/Navigation"
import Button from "../Button/Button"
import { motion } from "framer-motion"

import logo from "../../images/icon/new-logo.svg"
import arrowDown from "../../images/icon/arrow-down.png"
import AppContext from "../../context/AppContext"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "../../utils/ROUTES"
import { Trans } from "react-i18next"

function Footer() {
  const { REGISTER } = ROUTES

  const navigate = useNavigate()
  const handleButtonClick = () =>
    (window.location.href = "https://clients.zumzak.ru")
  // navigate(REGISTER)

  const { t } = useContext(AppContext)

  const variants = {
    hide: {
      opacity: 0,
    },

    show: {
      opacity: 1,
      transition: {
        duration: 0.3,
        delay: 0.4,
      },
    },
  }

  return (
    <footer className={styles.footer}>
      <div className="container_inner">
        <div className={styles.langSwitch}>
          <LanguageSwitcher />
        </div>

        <div className={styles.contactsAndLogo}>
          <Logo />
          <p className={styles.contact}>
            {t("footer.mail") + " "}
            <span> info@zumzak.com</span>
          </p>
          <p className={styles.contact}>
            {t("footer.phone") + " "}
            <span> +7 965 100 00 86</span>
          </p>

          <div className={styles.getShop}>
            <p>{t("footer.getStore")}</p>
            <img src={arrowDown} alt="Arrow Down" />
          </div>
        </div>

        <div className={styles.navAndContactButton}>
          <div className={styles.navWrap}>
            <Navigation />
          </div>

          <Button
            footer
            click={handleButtonClick}
            text={t("buttons.partner")}
          />
        </div>

        <div className={styles.copyrightAndPolicy}>
          <motion.p
            variants={variants}
            initial="hide"
            whileInView="show"
            className={styles.copyright}
          >
            {<Trans i18nKey={"footer.copyright"} />}
          </motion.p>

          <motion.ul variants={variants} initial="hide" whileInView="show">
            <li className={styles.listItem}>
              <a
                className={styles.link}
                href="/Политика_конфиденциальности.pdf"
                target="_blank"
              >
                {t("footer.policy")}
              </a>
            </li>
            <li className={styles.listItem}>
              <a
                className={styles.link}
                href="/Пользовательское_соглашение.pdf"
                target="_blank"
              >
                {t("footer.use")}
              </a>
            </li>
            <li className={styles.listItem}>
              <a
                className={styles.link}
                href="/Уведомление_об_отказе_от_ответственности.pdf"
                target="_blank"
              >
                {t("footer.payment")}
              </a>
            </li>
          </motion.ul>

          <motion.div
            className={styles.robowebLinkContainer}
            variants={variants}
            initial="hide"
            whileInView="show"
          >
            <p>{t("footer.dev")}</p>
            <a
              // style={{ width: "250px" }}
              target="_blank"
              rel="noreferrer"
              href="https://itacademy.tech/"
            >
              <img
                // style={{ width: "200px" }}
                src={logo}
                alt="Logo IT-ACADEMY"
              />

              {/* <span>Roboweb</span>.Team */}
            </a>
          </motion.div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
