import React, { useContext, useState } from 'react'
import styles from "../sections/FirstScreenSection/FirstScreenSection.module.sass"
import GoBackButton from "../Button/GoBackButton"
import SimpleInput from "../inputs/SimpleInput/SimpleInput"
import SelectInput from "../inputs/SelectInput/SelectInput"
import PasswordInput from "../inputs/PasswordInput"
import CheckBox from "../inputs/CheckBox/CheckBox"
import ButtonCyan from "../Button/ButtonCyan"
import FirstScreenSection from "../sections/FirstScreenSection/FirstScreenSection"
import AppContext from "../../context/AppContext"
import russianCities from '../../utils/russian-cities.json'
import ChooseCityInput from "../inputs/ChooseCityInput/ChooseCityInput"
import InputMask from 'react-input-mask'
import styleInput from '../../components/inputs/SimpleInput/SimpleInput.module.sass'

function RegisterForm() {
  const { t } = useContext(AppContext)
  const [cities, setCities] = useState(russianCities.map(c => c.name))
  const [citiesToRender, setCitiesToRender] = useState([])
  const [cityValue, setCityValue] = useState('')
  const [isCitiesActive, setIsCitiesActive] = useState(false)

  const handleCityChange = evt => {
    const { value } = evt.target

    const citiesArr = cities.filter(c => c.toLowerCase().includes(value.toLowerCase()))

    setCityValue(value)
    setCitiesToRender(citiesArr)

    setIsCitiesActive(true)

    if (value === '' || !citiesArr.length) {
      setCitiesToRender([])
      setIsCitiesActive(false)
    }
  }

  const onItemClick = evt => {
    setIsCitiesActive(false)
    setCityValue(evt.target.textContent)
    setCitiesToRender(cities.filter(c => c.toLowerCase().includes(evt.target.textContent.toLowerCase())))
  }

  return (
    <FirstScreenSection>
      <div className="container_inner">
        <form>
          <div className={styles.registerContainer}>
            <GoBackButton text={t('buttons.goBack')} />
            <h2 className={styles.formTitle}>{ t('register.title') }</h2>
            <p className={styles.formSubTitle}>{ t('register.subTitle') }</p>

            <div className={styles.registerFormContainer}>
              <div className={styles.registerFormLeftCol}>
                <SimpleInput type={''} name={''} placeholder={t('form.name')} />
                <SimpleInput type={''} name={''} placeholder={t('form.lastName')} />
                <SimpleInput type={''} name={''} placeholder={t('form.mail')} />
                <InputMask
                  mask='+7 999 999 99 99'
                  placeholder={t('form.phone')}
                  className={styleInput.input} />

              </div>
              <div className={styles.registerFormRightCol}>
                <div className={styles.countryAndCityContainer}>
                  {/*<SelectInput defaultValue={t('form.country')}>*/}
                  <SelectInput defaultValue={'Россия'}>
                    {/*<option>Россия</option>*/}
                    {/*<option>Турция</option>*/}
                    {/*<option>Китай</option>*/}
                  </SelectInput>

                  <ChooseCityInput
                    citiesArray={citiesToRender}
                    onChange={handleCityChange}
                    onItemClick={onItemClick}
                    value={cityValue}
                    isActive={isCitiesActive}
                  />

                </div>

                <div className={styles.passwords}>
                  <PasswordInput labelText={t('form.password')} />
                  <PasswordInput labelText={t('form.passwordDub')} />
                </div>

                <div className={styles.checkboxes}>
                  <CheckBox id={'agreement'} text={t('form.agreement')} />
                  <CheckBox id={'policy'} text={t('form.use')} />
                </div>

              </div>
            </div>

            <ButtonCyan type={'submit'} text={t('buttons.register')} />
          </div>
        </form>
      </div>
    </FirstScreenSection>
  )
}

export default RegisterForm
