import React from 'react'
import styles from './Button.module.sass'

function FeaturesButton({ buttonClick , text }) {
  return (
    <button
      onClick={buttonClick}
      className={`${styles.button} ${styles.features}`}>{ text }</button>
  )
}

export default FeaturesButton
