import React, {useContext} from 'react'
import styles from './QuickSellSection.module.sass'
import flat from '../../../images/quick-sell/flat.png'
import SectionTitle from "../../SectionTitle/SectionTitile"
import AppContext from "../../../context/AppContext"
import Number from "../../Number/Number";
import CardHeading from "../../Cards/CardHeading/CardHeading"

function QuickSellSection() {
  const { t } = useContext(AppContext)

  return (
    <section className={styles.section}>
      <div className="container_inner">
        <SectionTitle title={t('sectionTitles.quickSell')} margin={'50px 0 75px'} />

        <div className={styles.wrapper}>

          <div className={styles.leftCol}>
            <img src={flat} alt="Flat"/>
          </div>

          <div className={styles.rightCol}>

            <div className={styles.card}>
              <div className={styles.numContainer}>
                <Number number={1} />
              </div>
              <div>
                <CardHeading title={t('quickSellSection.paragraphOne.title')} />
                <p className={styles.text}>{ t('quickSellSection.paragraphOne.text') }</p>
              </div>
            </div>

            <div className={styles.card}>
              <div className={styles.numContainer}>
                <Number number={2} />
              </div>
              <div>
                <CardHeading title={t('quickSellSection.paragraphTwo.title')} />
                <p className={styles.text}>{ t('quickSellSection.paragraphTwo.text') }</p>
              </div>
            </div>

            <div className={styles.card}>
              <div className={styles.numContainer}>
                <Number number={3} />
              </div>

              <div>
                <CardHeading title={t('quickSellSection.paragraphThree.title')} />
                <p className={styles.text}>{ t('quickSellSection.paragraphThree.text') }</p>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  )
}

export default QuickSellSection
