import React, { useState } from 'react'
import styles from './SimpleInput/SimpleInput.module.sass'
import SimpleInput from "./SimpleInput/SimpleInput"
import eye from '../../images/icon/eye.png'
import eyeClose from '../../images/icon/eye-off.png'

function PasswordInput({ labelText }) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const handleIconClick = () => setIsPasswordVisible(prev => !prev)

  return (
    <>
      <label className={styles.label}>
        <p className={styles.labelText}>{ labelText }</p>
        <SimpleInput type={isPasswordVisible ? 'text' : 'password'} name={'password'} placeholder={''} />

        <div
          onClick={handleIconClick}
          className={
            labelText
              ? styles.iconContainer
              : `${styles.iconContainer} ${styles.iconContainer_withoutLabel}`
          }>

          {
            !isPasswordVisible
              ? <img src={eye} alt="Eye"/>
              : <img src={eyeClose} alt="Eye"/>
          }

        </div>
      </label>
    </>
  )
}

export default PasswordInput
