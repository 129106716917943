import React, { useState, useEffect, useContext } from "react"
import styles from "./MainContentSection.module.sass"
import FirstScreenForm from "../../FirstScreenForm/FirstScreenForm"
import girl from "../../../images/first-screen/girl.png"
import bottom from "../../../images/first-screen/bottom.svg"
import leg from "../../../images/first-screen/leg.png"
import windowBig from "../../../images/first-screen/window.png"
import windowSmall from "../../../images/first-screen/window-small.png"
import rate from "../../../images/first-screen/rate.png"
import rateMob from "../../../images/first-screen/rate-mob.png"
import { motion } from "framer-motion"
import AppContext from "../../../context/AppContext"
import RubleIcon from "../../icons/RubleIcon"
import DollarIcon from "../../icons/DollarIcon"
import Button from "../../Button/ButtonCyan"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "../../../utils/ROUTES"

function MainContentSection() {
  const [symbols, setSymbols] = useState("")
  const { t, language } = useContext(AppContext)

  const navigate = useNavigate()
  const handleButtonClick = () =>
    (window.location.href = "https://clients.zumzak.ru")

  const titleVariant = {
    hide: {
      opacity: 0,
      // x: -100,
    },

    show: {
      opacity: 1,
      // x: 0,
    },
  }

  const rightColVariant = {
    hide: {
      opacity: 0,
      x: 200,
    },

    show: {
      opacity: 1,
      x: 127,
    },
  }

  const printSymbol = (str) => {
    setSymbols("")

    str.split("").forEach((el, index) => {
      setTimeout(() => {
        setSymbols((prev) => prev + el)
      }, 40 * index)
    })
  }

  useEffect(() => {
    if (language === "ru")
      return printSymbol("готовый инструмент для онлайн продаж!")
    printSymbol("a ready-made tool for online sales!")
    // eslint-disable-next-line
  }, [language])

  return (
    <section className={"container_inner"}>
      <div className={styles.wrapper}>
        <div className={styles.leftCol}>
          <motion.h1
            initial="hide"
            whileInView="show"
            transition={{ duration: 0.3, delay: 0.6 }}
            variants={titleVariant}
            className={styles.title}
          >
            <span>ZumZak</span>
            <span className={styles.defis}> — </span>
            {/*<span className={styles.printSymbols}>*/}
            {/*  { symbols }*/}
            {/*</span>*/}
            {t("mainTitle")}
          </motion.h1>

          <motion.p
            initial="hide"
            whileInView="show"
            transition={{ duration: 0.5, delay: 0.8 }}
            variants={titleVariant}
            className={styles.subTitle}
          >
            {t("mainSubtitle")}
          </motion.p>

          <motion.p
            initial="hide"
            whileInView="show"
            transition={{ duration: 0.5, delay: 0.8 }}
            variants={titleVariant}
            className={`${styles.subTitle} ${styles.subTitle_mob}`}
          >
            {t("mainSubtitleMob.one")}
            <span>{t("mainSubtitleMob.two")}</span>
            {t("mainSubtitleMob.three")}
            <span>{t("mainSubtitleMob.four")}</span>
            {t("mainSubtitleMob.five")}
          </motion.p>

          <FirstScreenForm />
        </div>

        <motion.div
          initial="hide"
          whileInView="show"
          transition={{ duration: 0.3, delay: 1 }}
          variants={rightColVariant}
          className={styles.rightCol}
        >
          <img className={styles.bottomImg} src={bottom} alt="Bottom" />
          <img className={styles.girlImg} src={girl} alt="Girl" />
          <img className={styles.leg} src={leg} alt="Leg" />
          <img className={styles.windowBig} src={windowBig} alt="Window" />
          <img className={styles.windowSmall} src={windowSmall} alt="Window" />
          <img className={styles.rate} src={rate} alt="Rate" />
          <img className={styles.rate_mob} src={rateMob} alt="Rate" />

          {language === "ru" ? (
            <div className={styles.rubleContainer}>
              <RubleIcon />
            </div>
          ) : (
            <div className={styles.dollarContainer}>
              <DollarIcon />
            </div>
          )}
        </motion.div>

        <div className={styles.buttonContainer}>
          <Button text={t("buttons.partner")} click={handleButtonClick} />
        </div>
      </div>
    </section>
  )
}

export default MainContentSection
