import React, { useContext } from 'react'
import styles from './SectionWithVideo.module.sass'
import SectionTitle from "../../SectionTitle/SectionTitile"
import CardHeading from "../../Cards/CardHeading/CardHeading"
import { motion } from "framer-motion"
import AppContext from "../../../context/AppContext"
import FirstVideo from "../../FirstVideo/FirstVideo"
import CheckIcon from "../../CheckIcon/CheckIcon";

function SectionWithVideo({ title, id }) {
  const { t } = useContext(AppContext)

  const leftColVariant = {
    hide: {
      opacity: 0,
      x: -100,
    },

    show: {
      opacity: 1,
      x: 0,
    }
  }

  const rightColVariant = {
    hide: {
      opacity: 0,
      x: 100,
    },

    show: {
      opacity: 1,
      x: 0,
    }
  }

  return (
    <section id={id} className={styles.section}>
      <div className="container_inner">
        <SectionTitle title={title} margin={'10px 0 47px'} />

        <div className={styles.wrapper}>

          <motion.div
            variants={leftColVariant}
            initial="hide"
            whileInView="show"
            transition={{ duration: 0.5,delay: 0.4 } }
            className={styles.leftCol}>
            <div className={styles.videoContainer}>

              {/*<iframe*/}
              {/*  width="100%"*/}
              {/*  height="100%"*/}
              {/*  src="https://rutube.ru/play/embed/a8f6892f1a15adf0892ff41e7a10b533"*/}
              {/*  frameBorder="0"*/}
              {/*  allow="clipboard-write"*/}
              {/*  webkitAllowFullScreen*/}
              {/*  mozallowfullscreen*/}
              {/*  allowFullScreen></iframe>*/}

              <FirstVideo />

            </div>
          </motion.div>

          {
            title &&
              <motion.div
                variants={rightColVariant}
                initial="hide"
                whileInView="show"
                transition={{ duration: 0.5, delay: 0.3 } }
                className={styles.rightCol}>
                <p>
                  { t('aboutSection.textOne.partOne') } <span>{ t('aboutSection.span')  }</span> { t('aboutSection.textOne.partTwo')  }
                </p>

                <p>
                  { t('aboutSection.textTwo') } <span>{ t('aboutSection.span')  }</span>
                </p>

                <p className={styles.boldText}>
                  { t('aboutSection.textThree') }
                </p>
              </motion.div>
          }

          {/*{*/}
          {/*  title &&*/}
          {/*    <motion.div*/}
          {/*      variants={rightColVariant}*/}
          {/*      initial="hide"*/}
          {/*      whileInView="show"*/}
          {/*      transition={{ duration: 0.5, delay: 0.3 } }*/}
          {/*      className={`${styles.rightCol} ${styles.rightCol_with_title}`}>*/}

          {/*      <div className={styles.heading}>*/}
          {/*        <CheckIcon width={27} height={27} />*/}
          {/*        <CardHeading number={'1'} title={t('quickSellSection.paragraphOne.title')} />*/}
          {/*      </div>*/}
          {/*      <p className={styles.paragraph}>*/}
          {/*        { t('quickSellSection.paragraphOne.text') }*/}
          {/*      </p>*/}


          {/*      <div className={styles.heading}>*/}
          {/*        <CheckIcon width={27} height={27} />*/}
          {/*        <CardHeading number={'2'} title={t('quickSellSection.paragraphTwo.title')} />*/}
          {/*      </div>*/}
          {/*      <p className={styles.paragraph}>*/}
          {/*        { t('quickSellSection.paragraphTwo.text') }*/}
          {/*      </p>*/}

          {/*      <div className={styles.heading}>*/}
          {/*        <CheckIcon width={27} height={27} />*/}
          {/*        <CardHeading number={'3'} title={t('quickSellSection.paragraphThree.title')} />*/}
          {/*      </div>*/}
          {/*      <p className={styles.paragraph}>*/}
          {/*        { t('quickSellSection.paragraphThree.text') }*/}
          {/*      </p>*/}
          {/*    </motion.div>*/}
          {/*}*/}

        </div>
      </div>
    </section>
  )
}

export default SectionWithVideo
