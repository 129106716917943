import React from 'react'
import styles from './Button.module.sass'

function Button({ type = '', text, click }) {

  return (
    <button onClick={click} type={type} className={`${styles.button} ${styles.button_white}`}>
      { text }
    </button>
  )
}

export default Button
