import React from 'react'
import poster from "../../videos/features/poster.png"
import video from '../../videos/features/video.mp4'

function FirstVideo() {
  return (
    <video
      width="100%"
      height="100%"
      poster={poster}
      controls
      preload='true'
      muted
    >
      <source src={video} type="video/mp4"/>
    </video>
  )
}

export default FirstVideo
