import React, { useContext } from 'react'
import styles from './FAQSection.module.sass'
import SectionTitle from "../../SectionTitle/SectionTitile"
import CollapseItem from "../../CollapseItem/CollapseItem"
import { NAVIGATION } from "../../../utils/NAVIGATION"
import AppContext from "../../../context/AppContext"
import { Trans } from "react-i18next"

function FaqSection() {
  const { FAQ } = NAVIGATION
  const { t } = useContext(AppContext)

  return (
    <section id={FAQ} className={styles.section}>
      <div className="container_inner">
        <SectionTitle title={t('sectionTitles.faq')} margin={'110px 0 60px'} />

        <CollapseItem
          title={t('faqSection.one.title')}
          text={
            [
              t('faqSection.one.text')
            ]} />

        <CollapseItem
          title={t('faqSection.two.title')}
          text={
            [
              <Trans i18nKey={'faqSection.two.text'} />
            ]} />

        <CollapseItem
          title={t('faqSection.three.title')}
          text={
            [
              t('faqSection.three.text')
            ]} />

        <CollapseItem
          title={t('faqSection.four.title')}
          text={
            [
              t('faqSection.four.text.partOne'),
              t('faqSection.four.text.partTwo')
            ]} />

        <CollapseItem
          title={t('faqSection.five.title')}
          text={
            [
              <Trans i18nKey={'faqSection.five.text'} />
            ]} />
      </div>
    </section>
  )
}

export default FaqSection
