import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import styles from "./FirstScreenForm.module.sass"
import ButtonCyan from "../Button/ButtonCyan"
import { motion } from "framer-motion"
import { ROUTES } from "../../utils/ROUTES"
import AppContext from "../../context/AppContext"

function FirstScreenForm() {
  const { t } = useContext(AppContext)

  const variants = {
    hide: {
      opacity: 0,
      y: 100,
    },

    show: {
      opacity: 1,
      y: 0,
    },
  }

  const navigate = useNavigate()
  const handleButtonClick = () =>
    (window.location.href = "https://clients.zumzak.ru")

  return (
    <>
      <motion.form
        variants={variants}
        initial="hide"
        whileInView="show"
        transition={{ duration: 0.3, delay: 0.8 }}
        className={styles.form}
      >
        {/* <input
        style={{ marginRight: '10px' }}
        placeholder={t('firstScreenFormPlaceholder')}
        type="text"
      /> */}
      </motion.form>
      <div className={styles.buttonBox}>
        <ButtonCyan click={handleButtonClick} text={t("buttons.partner")} />
      </div>
    </>
  )
}

export default FirstScreenForm
