import React from 'react'

function DollarIcon({ width = 58, height = 58, color = '#479EBE' }) {
  return (
    <svg width="35" height="50" viewBox="0 0 35 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.2847 27.5284C31.6909 24.1813 28.3438 22.5875 24.8374 21.4718C20.8527 20.1967 16.7087 19.7186 14.9555 18.6029C14.4773 18.2841 14.1586 17.806 14.1586 17.3278C14.1586 16.2121 15.2743 15.2558 18.3026 15.0964C18.462 15.0964 18.7807 15.0964 18.9401 15.0964C21.9684 15.2558 24.9967 16.0527 28.3438 17.6465C28.6626 17.8059 28.8219 17.9653 29.1407 17.9653L30.097 15.8933L33.1253 9.19919C31.2127 8.0835 28.9813 7.28657 26.5905 6.64903C25.3155 6.33026 23.881 6.01149 22.4466 5.85211L22.6059 2.50502V0.273652L15.2743 0.114258L15.1149 5.85211C6.50813 6.80841 2.04534 11.7493 1.88596 17.6465C1.88596 19.7185 2.20474 21.3124 3.00167 22.5875C3.95797 24.3407 5.39244 25.6158 7.14567 26.5721C10.0146 28.3253 13.6804 28.9629 16.7087 29.7598C19.737 30.5567 21.9684 31.1942 21.9684 32.7881C21.9684 34.0631 20.6933 34.7007 17.1869 34.7007C15.7524 34.7007 14.1586 34.3819 12.7241 34.0632C10.0146 33.4256 7.46442 32.3099 5.23304 31.0348L0.929688 39.9604C2.04538 40.7573 3.47984 41.3949 4.9143 42.0324C7.62383 42.9887 10.9709 43.7856 14.318 44.1044L14.1586 47.2921V49.6828L21.4903 49.8422L21.6497 43.945C29.7783 42.8293 34.0816 37.8884 34.241 32.3099C34.241 30.3973 33.9222 28.8035 33.2847 27.5284Z" fill={color}/>
    </svg>

  )
}

export default DollarIcon
