import React, { useContext } from 'react'
import FirstScreenSection from "../sections/FirstScreenSection/FirstScreenSection"
import GoBackButton from "../Button/GoBackButton"
import styles from "../sections/FirstScreenSection/FirstScreenSection.module.sass"
import SimpleInput from "../inputs/SimpleInput/SimpleInput"
import CheckBox from "../inputs/CheckBox/CheckBox"
import ButtonCyan from '../Button/ButtonCyan'
import AppContext from "../../context/AppContext"
import styleInput from "../inputs/SimpleInput/SimpleInput.module.sass"
import InputMask from "react-input-mask"
import PasswordInput from "../inputs/PasswordInput";

function LoginForm() {
  const { t } = useContext(AppContext)

  return (
    <FirstScreenSection>
      <div className="container_inner">
        <form>
          <div className={styles.registerContainer}>

            <GoBackButton text={t('buttons.goBack')} />
            <h2 className={`${styles.formTitle} ${styles.formTitle_login}`}>{ t('login.title') }</h2>

            <div className={styles.loginFormContainer}>
              {/*<InputMask*/}
              {/*  mask='+7 999 999 99 99'*/}
              {/*  placeholder={t('form.phone')}*/}
              {/*  className={styleInput.input} />*/}

              <SimpleInput
                type={'email'}
                name={'email'}
                placeholder={t('form.mail')}
              />

              <PasswordInput />

              <div className={styles.checkboxes}>
                <CheckBox id={'agreement'} text={t('form.agreement')} />
                <CheckBox id={'policy'} text={t('form.use')} />
              </div>

            </div>

            <ButtonCyan type={'submit'} text={t('buttons.login')} />

            <p className={styles.forgetPasswordLink}>{ t('login.forgetPass.text') }<a href="/">{ t('login.forgetPass.link') }</a></p>
          </div>
        </form>
      </div>
    </FirstScreenSection>
  )
}

export default LoginForm
