import React from 'react'
import styles from './Button.module.sass'

function ShowMoreButton({ text, click }) {
  return (
    <button
      onClick={click}
      className={`${styles.button} ${styles.showMoreButton}`}>{ text }</button>
  )
}

export default ShowMoreButton
