import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './Button.module.sass'

function Button({ type = '', text }) {

  const navigate = useNavigate()
  const handleButtonClick = () => navigate('/')

  return (
    <button onClick={handleButtonClick} type={type} className={`${styles.button} ${styles.goBackButton}`}>
      { text }
    </button>
  )
}

export default Button
