import React, { useState } from 'react'
import style from './CheckBox.module.sass'

function CheckBox({ id, text }) {
  const [checked, setChecked] = useState(false)

  const handleCheckBoxClick = () => setChecked(prev => !prev)

  return (
    <div className={style.checkbox}>
      <input className={style.realCheckBox} id={id} type="checkbox" checked={checked} />
      <div onClick={handleCheckBoxClick} className={style.visibleCheckBox}>
        <div />
      </div>
      <label
        onClick={handleCheckBoxClick}
        className={style.label}
        htmlFor={id}>{ text }</label>
    </div>
  )
}

export default CheckBox
