import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import styles from "./Header.module.sass"
import Navigation from "../Navigation/Navigation"
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher"
import Button from "../Button/Button"
import ButtonWhite from "../Button/ButtonWhite"
import Logo from "../Logo/Logo"
import AppContext from "../../context/AppContext"
import { ROUTES } from "../../utils/ROUTES"

function Header() {
  const { isBurgerActive, setIsBurgerActive, t } = useContext(AppContext)

  const classes = {
    burger: isBurgerActive
      ? `${styles.burgerContainer} ${styles.burgerContainer_active}`
      : styles.burgerContainer,
  }

  const navigate = useNavigate()

  const handleBurgerClick = () => setIsBurgerActive((prev) => !prev)
  const handleRegisterClick = () =>
    (window.location.href = "https://clients.zumzak.ru/register")
  const handleLoginClick = () =>
    (window.location.href = "https://clients.zumzak.ru")

  return (
    <header className={styles.header}>
      <div className="container_max">
        <div className={styles.topWrapper}>
          <Logo />
          <Navigation header />
          <div className={styles.buttons}>
            <ButtonWhite
              click={handleRegisterClick}
              text={t("buttons.getStarted")}
            />
            <Button
              styles={{ marginBottom: "10px" }}
              click={handleLoginClick}
              text={t("buttons.login")}
            />
          </div>

          <div onClick={handleBurgerClick} className={classes.burger}>
            <div />
            <div />
            <div />
          </div>
        </div>

        <div className={styles.bottomWrapper}>
          <LanguageSwitcher />
        </div>
      </div>
    </header>
  )
}

export default Header
