import React from 'react'
import styles from './Button.module.sass'

function ButtonCyanOutline({ type = '', text, click }) {

  return (
    <button onClick={click} type={type} className={`${styles.button} ${styles.button_cyan}`}>
      { text }
    </button>
  )
}

export default ButtonCyanOutline
