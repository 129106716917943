import React, { useContext } from 'react'
import styles from './TarifsSection.module.sass'
import SectionTitle from "../../SectionTitle/SectionTitile"
import TarifCard from "../../Cards/TarifCard/TarifCard"
import { motion } from "framer-motion"
import { NAVIGATION } from "../../../utils/NAVIGATION"
import AppContext from "../../../context/AppContext"

function TarifsSection() {
  const { TARIFS } = NAVIGATION
  const { t } = useContext(AppContext)

  const container = {
    hidden: {
      opacity: 0
    },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.15
      }
    }
  }

  return (
    <section id={TARIFS} className={styles.section}>
      <div className="container_inner">
        <SectionTitle title={t('sectionTitles.tariffs')} margin={'110px 0 70px'} />

        <motion.ul
          variants={container}
          initial="hidden"
          whileInView="show"
          className={styles.wrapper}>
          <TarifCard
            title={t('tariffs.free.title')}
            goodsQuantity={'1500'}
            text={t('tariffs.free.text')}
            price={'0'}
          />

          <TarifCard
            title={t('tariffs.standard.title')}
            goodsQuantity={'10 000'}
            text={t('tariffs.standard.text')}
            price={'1495'}
          />

          <TarifCard
            title={t('tariffs.premium.title')}
            goodsQuantity={'50 000'}
            text={t('tariffs.premium.text')}
            price={'2985'}
          />

          <TarifCard
            title={t('tariffs.gold.title')}
            goodsQuantity={'100 000'}
            text={t('tariffs.gold.text')}
            price={'5985'}
          />
        </motion.ul>
      </div>
    </section>
  )
}

export default TarifsSection
