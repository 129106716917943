import React, { useContext, useState } from 'react'
import styles from './ReasonsSection.module.sass'
import SectionTitle from "../SectionTitle/SectionTitile"
import CardLarge from "../Cards/CardLarge/CardLarge"
import CardSmall from "../Cards/CardSmall/CardSmall"
import { Trans } from "react-i18next"

import computer from '../../images/reasons/computer.png'
import support from '../../images/reasons/support.png'
import product from '../../images/reasons/product.png'
import phone from '../../images/reasons/phone.png'
import ShowMoreButton from "../Button/ShowMoreButton"
import AppContext from "../../context/AppContext"

function ReasonsSection() {
  const { t } = useContext(AppContext)

  const [showMore, setShowMore] = useState(false)

  const showMoreHandler = () => setShowMore(prev => !prev)

  return (
    <section className={styles.section}>
      <div className="container_inner">
        <SectionTitle title={t('sectionTitles.reasons')} margin={'0 0 97px'} />
        {/*desktop*/}
        <div className={styles.gridContainer}>

          <CardLarge
            reverse
            number={'1'}
            title={t('advantageSection.cardOne.title')}
            text={[
              t('advantageSection.cardOne.text.partOne'),
              t('advantageSection.cardOne.text.partTwo')
            ]}
            img={computer}
            rocket
          />

          <CardSmall
            number={'2'}
            title={t('advantageSection.cardTwo.title')}
            text={[
              // <span>ZumZak</span>,
              <Trans i18nKey={'advantageSection.cardTwo.text.partOne'} />,
              // t('advantageSection.cardTwo.text.partOne'),
              t('advantageSection.cardTwo.text.partTwo')
            ]}
          />

          <CardSmall
            number={'3'}
            title={t('advantageSection.cardThree.title')}
            text={[t('advantageSection.cardThree.text')]}
            start={1}
            end={3}
          />

          <CardLarge
            reverse
            number={'4'}
            title={t('advantageSection.cardFour.title')}
            text={[t('advantageSection.cardFour.text')]}
            img={product}
            start={3}
            end={6}
          />

          <CardLarge
            reverse
            img={phone}
            number={'5'}
            title={t('advantageSection.cardFive.title')}
            text={[
              t('advantageSection.cardFive.text.partOne'),
              // t('advantageSection.cardFive.text.partTwo')
              ]}
          />

          <CardSmall
            number={'6'}
            title={t('advantageSection.cardSix.title')}
            text={[t('advantageSection.cardSix.text')]}
          />

          <CardSmall
            number={'7'}
            title={t('advantageSection.cardSeven.title')}
            text={[t('advantageSection.cardSeven.text')]}
            start={1}
            end={3}
          />

          <CardLarge
            reverse
            img={support}
            number={'8'}
            title={t('advantageSection.cardEight.title')}
            text={[
              t('advantageSection.cardEight.text.partOne'),
              t('advantageSection.cardEight.text.partTwo')
            ]}
            start={3}
            end={6}
          />

        </div>

        {/*mobile*/}
        <div className={`${styles.gridContainer} ${styles.gridContainer_mob}`}>

          <CardLarge
            number={'1'}
            title={t('advantageSection.cardOne.title')}
            text={[
              t('advantageSection.cardOne.text.partOne'),
              t('advantageSection.cardOne.text.partTwo')
            ]}
            img={computer}
            rocket
          />

          <CardSmall
            number={'2'}
            title={t('advantageSection.cardTwo.title')}
            text={[
              <Trans i18nKey={'advantageSection.cardTwo.text.partOne'} />,
              t('advantageSection.cardTwo.text.partTwo')
            ]}
          />

          <CardSmall
            number={'3'}
            title={t('advantageSection.cardThree.title')}
            text={[t('advantageSection.cardThree.text')]}
            start={1}
            end={3}
          />

          <CardLarge
            number={'4'}
            title={t('advantageSection.cardFour.title')}
            text={[t('advantageSection.cardFour.text')]}
            img={product}
            start={3}
            end={6}
          />

          {
            showMore && <>
              <CardLarge
                img={phone}
                number={'5'}
                title={t('advantageSection.cardFive.title')}
                text={[
                  t('advantageSection.cardFive.text.partOne'),
                  // t('advantageSection.cardFive.text.partTwo')
                ]}
              />

              <CardSmall
                number={'6'}
                title={t('advantageSection.cardSix.title')}
                text={[t('advantageSection.cardSix.text')]}
              />

              <CardSmall
                number={'7'}
                title={t('advantageSection.cardSeven.title')}
                text={[t('advantageSection.cardSeven.text')]}
                start={1}
                end={3}
              />

              <CardLarge
                img={support}
                number={'8'}
                title={t('advantageSection.cardEight.title')}
                text={[
                  t('advantageSection.cardEight.text.partOne'),
                  t('advantageSection.cardEight.text.partTwo')
                ]}
                start={3}
                end={6}
              />
            </>
          }

          <ShowMoreButton
            text={showMore ? t('buttons.showMoreReasons.hide') : t('buttons.showMoreReasons.show')}
            click={showMoreHandler} />
        </div>
      </div>
    </section>
  )
}

export default ReasonsSection
