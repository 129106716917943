import React from 'react'
import styles from './MapMarker.module.sass'
import marker from '../../images/map-servers/marker.svg'
import { motion } from "framer-motion"

function MapMarker({ nameOfClass }) {
  const classes = {
    img: styles[nameOfClass]
  }

  const randomDelayOrDuration = (min, max) => {
    const delay = Math.random() * (max - min) + min
    return delay.toFixed(1)
  }

  const variants = {
    hidden: {
      y: -85,
      opacity: 0
    },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: randomDelayOrDuration(0.5, 1),
        delay: randomDelayOrDuration(0.1, 0.9),
      }
    }
  }

  return (
    <motion.img
      variants={variants}
      initial="hidden"
      whileInView="show"
      className={`${styles.marker} ${classes.img}`} src={marker} alt="Marker"/>
  )
}

export default MapMarker
