import React, { useContext } from 'react'
import FirstScreenSection from "../components/sections/FirstScreenSection/FirstScreenSection"
import Header from "../components/Header/Header"
import MainContentSection from "../components/sections/MainContentSection/MainContentSection"
import SectionWithVideo from "../components/sections/SectionWithVideo/SectionWithVideo"
import NeedOnlineShopSection from "../components/sections/NeedOnlineShopSection/NeedOnlineShopSection"
import ReasonsSection from "../components/ReasonsSection/ReasonsSection"
import PlatformSection from "../components/sections/PlatformSection/PlatformSection"
import TarifsSection from "../components/sections/TarifsSection/TarifsSection"
import FaqSection from "../components/sections/FAQSection/FAQSection"
import ServerSection from "../components/sections/ServerSection/ServerSection"
import Footer from "../components/Footer/Footer"
import { NAVIGATION } from "../utils/NAVIGATION"
import FeaturesSection from "../components/sections/FeaturesSection/FeaturesSection"
import AppContext from "../context/AppContext"
import QuickSellSection from "../components/sections/QuickSellSection/QuickSellSection";

function MainPage() {
  const { ABOUT } = NAVIGATION
  const { t } = useContext(AppContext)

  return (
    <>
      <FirstScreenSection isArrow>
        <Header />
        <MainContentSection />
      </FirstScreenSection>
      <SectionWithVideo id={ABOUT} title={t('sectionTitles.aboutZumzak')} />
      <NeedOnlineShopSection />
      <ReasonsSection />
      <FeaturesSection />
      <QuickSellSection />
      {/*<SectionWithVideo title={t('sectionTitles.quickSell')} />*/}
      <PlatformSection />
      <TarifsSection />
      <FaqSection />
      <ServerSection />
      <Footer />
    </>
  )
}

export default MainPage
