import React, { useContext, useState } from 'react'
import styles from './FeaturesSection.module.sass'
import FeaturesListItem from "./FeaturesListItem"
import FeaturesButton from "../../Button/FeaturesButton"
import wave from '../../../images/wave-min.svg'
import waveBottom from '../../../images/wave-min-bottom.svg'
import phone from '../../../images/features/phone-3.png'
import { NAVIGATION } from "../../../utils/NAVIGATION"
import AppContext from "../../../context/AppContext"
import { motion } from "framer-motion"

function FeaturesSection() {
  const [showMore, setShowMore] = useState(false)
  const onButtonClick = () => setShowMore(prev => !prev)

  const { FEATURES } = NAVIGATION
  const { t } = useContext(AppContext)

  const getVariants = (xStart, yStart, delay = 0, duration = 0.3) => {
    return {
      hide: {
        x: xStart,
        y: yStart,
        opacity: 0
      },

      show: {
        x: 0,
        y: 0,
        opacity: 1,
        transition: {
          delay, duration
        }
      }
    }
  }

  return (
    <section id={FEATURES} className={styles.section}>
      {/*<div className={styles.wave} />*/}
      <img className={styles.waveTop} src={wave} alt=""/>
      <img className={styles.waveBottom} src={waveBottom} alt=""/>

      <div className="container_inner">
        <div className={styles.content}>

          <img className={styles.phone} src={phone} alt="Phone"/>

          <h2 className={styles.title}>{ t('featuresSection.title') }</h2>
          <ul className={styles.list}>
            <FeaturesListItem title={t('featuresSection.text.one.title')} text={t('featuresSection.text.one.text')} />
            <FeaturesListItem title={t('featuresSection.text.two.title')} text={t('featuresSection.text.two.text')} />
            <FeaturesListItem title={t('featuresSection.text.three.title')} text={t('featuresSection.text.three.text')} />
            <FeaturesListItem title={t('featuresSection.text.four.title')} text={t('featuresSection.text.four.text')} />
            <FeaturesListItem title={t('featuresSection.text.five.title')} text={t('featuresSection.text.five.text')} />
            <FeaturesListItem title={t('featuresSection.text.six.title')} text={t('featuresSection.text.six.text')} />
            <FeaturesListItem title={t('featuresSection.text.seven.title')} text={t('featuresSection.text.seven.text')} />
            <FeaturesListItem title={t('featuresSection.text.eight.title')} text={t('featuresSection.text.eight.text')} />

            {
              showMore &&
              <>
                <FeaturesListItem title={t('featuresSection.text.nine.title')} text={t('featuresSection.text.nine.text')} />
                <FeaturesListItem title={t('featuresSection.text.ten.title')} text={t('featuresSection.text.ten.text')} />
                <FeaturesListItem title={t('featuresSection.text.eleven.title')} text={t('featuresSection.text.eleven.text')} />
                <FeaturesListItem title={t('featuresSection.text.twelve.title')} text={t('featuresSection.text.twelve.text')} />
                <FeaturesListItem title={t('featuresSection.text.13th.title')} text={t('featuresSection.text.13th.text')} />
                <FeaturesListItem title={t('featuresSection.text.14th.title')} text={t('featuresSection.text.14th.text')} />
                <FeaturesListItem title={t('featuresSection.text.15th.title')} text={t('featuresSection.text.15th.text')} />
                <FeaturesListItem title={t('featuresSection.text.16th.title')} text={t('featuresSection.text.16th.text')} />
                <FeaturesListItem title={t('featuresSection.text.17th.title')} text={t('featuresSection.text.17th.text')} />
                <FeaturesListItem title={t('featuresSection.text.18th.title')} text={t('featuresSection.text.18th.text')} />
                <FeaturesListItem title={t('featuresSection.text.19th.title')} text={t('featuresSection.text.19th.text')} />
                <FeaturesListItem title={t('featuresSection.text.20th.title')} text={t('featuresSection.text.20th.text')} />
                <FeaturesListItem title={t('featuresSection.text.21th.title')} text={t('featuresSection.text.21th.text')} />
                <FeaturesListItem title={t('featuresSection.text.22th.title')} text={t('featuresSection.text.22th.text')} />
                <FeaturesListItem title={t('featuresSection.text.23th.title')} text={t('featuresSection.text.23th.text')} />
                <FeaturesListItem title={t('featuresSection.text.24th.title')} text={t('featuresSection.text.24th.text')} />
                <FeaturesListItem title={t('featuresSection.text.25th.title')} text={t('featuresSection.text.25th.text')} />
                <FeaturesListItem title={t('featuresSection.text.26th.title')} text={t('featuresSection.text.26th.text')} />
                <FeaturesListItem title={t('featuresSection.text.27th.title')} text={t('featuresSection.text.27th.text')} />
                <FeaturesListItem title={t('featuresSection.text.28th.title')} text={t('featuresSection.text.28th.text')} />
              </>
            }

          </ul>

          <FeaturesButton
            buttonClick={onButtonClick}
            text={showMore ? t('buttons.showMore.hide') : t('buttons.showMore.show')} />
        </div>

      </div>

      <div className={styles.group}>
        <motion.div
          variants={getVariants(-80, 0, 0.2, 0.3 )}
          initial="hide"
          whileInView="show"
          className={styles.cart} />
        <motion.div
          variants={getVariants(100, -10, 0.3, 0.5 )}
          initial="hide"
          whileInView="show"
          className={styles.rubleLeftBottom} />
        <motion.div
          variants={getVariants(200, -10, 0.4, 0.6 )}
          initial="hide"
          whileInView="show"
          className={styles.heart} />
        <motion.div
          variants={getVariants(50, 50, 0.1, 0.5 )}
          initial="hide"
          whileInView="show"
          className={styles.rubleLeftTop} />
        <motion.div
          variants={getVariants(0, 100, 0.6, 0.6 )}
          initial="hide"
          whileInView="show"
          className={styles.rubleTop} />
        <motion.div
          variants={getVariants(-120, 60, 0.5, 0.6 )}
          initial="hide"
          whileInView="show"
          className={styles.discount} />
        <motion.div
          variants={getVariants(-50, 10, 0, 0.6 )}
          initial="hide"
          whileInView="show"
          className={styles.rubleRight} />
        <motion.div className={styles.groupBg} />
      </div>

      {/*<img className={styles.macbook} src={flat} alt=""/>*/}

    </section>
  )
}

export default FeaturesSection
