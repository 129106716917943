import React from 'react'
import styles from './Number.module.sass'

function Number({ number }) {
  return (
    <div className={styles.number}>{ number }</div>
  )
}

export default Number
