import React from 'react'
import styles from './CardHeading.module.sass'

function CardHeading({ number, title }) {
  return (
    <div className={styles.heading}>
      {/*<div className={styles.number}>{ number }</div>*/}
      <h3 className={styles.title}>{ title }</h3>
    </div>
  )
}

export default CardHeading
