import React, { useContext } from "react"
import styles from "./PlatformSection.module.sass"
import platform from "../../../images/platform/platform.svg"
import bag from "../../../images/platform/bag-blue.svg"
import dollarYellow from "../../../images/platform/dollar-yellow.svg"
import dollarRed from "../../../images/platform/dollar-red.svg"
import rubleYellow from "../../../images/platform/ruble-yellow.svg"
import rubleRed from "../../../images/platform/ruble-red.svg"
import ButtonCyan from "../../Button/ButtonCyan"
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "../../../utils/ROUTES"
import { NAVIGATION } from "../../../utils/NAVIGATION"
import AppContext from "../../../context/AppContext"
import { Trans } from "react-i18next"

function PlatformSection() {
  const { PARTNER } = NAVIGATION
  const { t, language } = useContext(AppContext)

  const colAnim = (from, to) => {
    return {
      hide: {
        opacity: 0,
        x: from,
      },

      show: {
        opacity: 1,
        x: to,
      },
    }
  }

  const navigate = useNavigate()
  const handleButtonClick = () =>
    (window.location.href = "https://clients.zumzak.ru")
  // navigate(ROUTES.REGISTER)

  return (
    <section id={PARTNER} className={styles.section}>
      <div className="container_inner">
        <div className={styles.wrapper}>
          <motion.div
            variants={colAnim(-100, 0)}
            initial="hide"
            whileInView="show"
            className={styles.leftCol}
          >
            <div className={styles.paragraphs}>
              <p className={styles.text}>
                <Trans i18nKey={"platformSection.textOne"} />
              </p>

              <p className={styles.text}>{t("platformSection.textTwo")}</p>

              <p className={styles.text}>
                <Trans i18nKey={"platformSection.textThree"} />
              </p>
            </div>

            <ButtonCyan click={handleButtonClick} text={t("buttons.partner")} />
          </motion.div>

          <motion.div
            variants={colAnim(100, 0)}
            initial="hide"
            whileInView="show"
            className={styles.rightCol}
          >
            <img src={platform} alt="Platform" />
            <img className={styles.bag} src={bag} alt="Bag" />

            {language === "ru" ? (
              <img
                className={styles.rubleYellow}
                src={rubleYellow}
                alt="Ruble Yellow"
              />
            ) : (
              <img
                className={styles.dollarYellow}
                src={dollarYellow}
                alt="Dollar Yellow"
              />
            )}

            {language === "ru" ? (
              <img className={styles.rubleRed} src={rubleRed} alt="Ruble Red" />
            ) : (
              <img
                className={styles.dollarRed}
                src={dollarRed}
                alt="Dollar Red"
              />
            )}
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default PlatformSection
