import React, { useState } from 'react'
import styles from './FeaturesSection.module.sass'
import icon from '../../../images/icon/check-circle.svg'
import { motion } from "framer-motion"
import InfoIcon from "../../InfoIcon/InfoIcon";

function FeaturesListItem({ title, text }) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const variants = {
    hide: {
      opacity: 0
    },

    show: {
      opacity: 1
    }
  }

  const classes = {
    tooltip: isTooltipOpen
      ? `${styles.tooltip} ${styles.tooltip_active}`
      : styles.tooltip
  }

  const handleInfoClick = () => setIsTooltipOpen(prev => !prev)

  return (
    <motion.li
      variants={variants}
      initial="hide"
      whileInView="show"
      className={styles.listItem}>

      <div
        onMouseEnter={handleInfoClick}
        onMouseLeave={handleInfoClick}
        className={styles.info}
      >
        <InfoIcon />
        <span className={classes.tooltip}>{ text }</span>
      </div>

      <p
        onMouseEnter={handleInfoClick}
        onMouseLeave={handleInfoClick}
      >{ title }</p>
    </motion.li>
  )
}

export default FeaturesListItem
