import React, { useContext } from 'react'
import styles from './ChooseCityInput.module.sass'
import AppContext from "../../../context/AppContext"

function ChooseCityInput({ citiesArray, onChange, onItemClick, value, isActive }) {
  const { t } = useContext(AppContext)

  return (
    <label className={styles.wrapper}>
      <input
        value={value}
        onChange={onChange}
        className={styles.input}
        type={'text'}
        name={'city'}
        placeholder={t('form.city')}
        required />

        <ul className={
          isActive
            ? `${styles.citiesWrapper} ${styles.citiesWrapper_active}`
            : styles.citiesWrapper
        }>
          { citiesArray.map((c, index) => <li key={Math.random() * index} onClick={onItemClick} className={styles.listItem}>{ c }</li>) }
        </ul>
    </label>
  )
}

export default ChooseCityInput
