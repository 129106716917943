import React, { useContext } from 'react'
import styles from './ServerSection.module.sass'
import ServerAdvantage from "../../ServerAdvantage/ServerAdvantage"
import MapMarker from "../../MapMarker/MapMarker"
import AppContext from "../../../context/AppContext"

function ServerSection() {
  const { t } = useContext(AppContext)

  return (
    <section className={styles.section}>
      <div className="container_max">
        <div className={styles.wrapper}>
          <MapMarker nameOfClass={'one'} />
          <MapMarker nameOfClass={'two'} />
          <MapMarker nameOfClass={'three'} />
          <MapMarker nameOfClass={'four'} />
          <MapMarker nameOfClass={'five'} />
          <MapMarker nameOfClass={'six'} />
          <MapMarker nameOfClass={'seven'} />
          <MapMarker nameOfClass={'eight'} />

          <div className={styles.content}>
            <h3 className={styles.title}>{ t('serverSection.title') }</h3>

            <ServerAdvantage text={t('serverSection.one')} />
            <ServerAdvantage text={t('serverSection.two')} />
            <ServerAdvantage text={t('serverSection.three')} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServerSection
