import React from 'react'
import styles from './SelectInput.module.sass'

function SelectInput({ children, defaultValue }) {
  return (
    <div className={styles.wrapper}>
      <select defaultValue={'default'} className={styles.select}>
        <option disabled hidden value="default">{ defaultValue }</option>
        { children }
      </select>
    </div>
  )
}

export default SelectInput
