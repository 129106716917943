// import React, { useState, useContext } from 'react'
// import styles from './LanguageSwitcher.module.sass'
// import AppContext from "../../context/AppContext"

// function LanguageSwitcher() {
//   const [isActive, setIsActive] = useState(true)
//   const { language, setLanguage } = useContext(AppContext)

//   const handleClick = evt => {
//     if (!isActive) return

//     if (language === evt.target.textContent.toLowerCase()) return
//     if (language === 'en') {
//       setLanguage('ru')
//       setIsActive(false)
//     } else {
//       setLanguage('en')
//       setIsActive(false)
//     }

//     setTimeout(() => setIsActive(true), 1400)
//   }

//   return (
//     <ul className={styles.list}>
//       <li onClick={handleClick} className={language === 'ru' && isActive ? styles.active : undefined}>Ru</li>
//       <li onClick={handleClick} className={language === 'en' && isActive ? styles.active : undefined}>En</li>
//     </ul>
//   )
// }

// export default LanguageSwitcher

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './LanguageSwitcher.module.sass'

export default function LanguageSwitcher() {
	const { i18n } = useTranslation()

	const changeLanguage = locale => {
		i18n.changeLanguage(locale)
		localStorage.setItem('locale', locale)
	}

	const [checked, setChecked] = useState(false)

	const handlerLanguage = (check) => {
		setChecked(check)
		!checked
			? changeLanguage('en')
			: changeLanguage('ru')
	}

	return (
		<div onClick={() => handlerLanguage(!checked)} className={styles['locale-switcher']}>
			<div>EN</div>
			<div>RU</div>
			<div  className={styles['locale-switcher__button']} style={{left: checked && '35px'}} />
		</div>
	)
}

