import React from 'react'
import styles from './CardSmall.module.sass'
import CardHeading from "../CardHeading/CardHeading"
import { motion } from "framer-motion"
import Number from "../../Number/Number"

function CardSmall({ number, title, text, start = 4, end = 6 }) {
  const cardStyle = {
    gridColumnStart: start,
    gridColumnEnd: end,
  }

  const variants = {
    hidden: {
      opacity: 0
    },

    show: {
      opacity: 1
    }
  }

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      whileInView="show"
      transition={{ duration: 0.3 }}
      style={cardStyle}
      className={styles.cardSmall}>

      <div className={styles.numContainer}>
        <Number number={number} />
      </div>

      <div className={styles.colRight}>
        <CardHeading number={number} title={title} />

        <div className={styles.texts}>
          { text.map((t, i) => (<p key={i} className={styles.text}>{ t }</p>)) }
        </div>
      </div>

    </motion.div>
  )
}

export default CardSmall
