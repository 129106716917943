import React from 'react'

function RubleIcon({ width = 58, height = 58, color = '#479EBE' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8692_112853)">
        <path d="M49.5014 8.49848C38.1709 -2.83198 19.8307 -2.83368 8.49861 8.49848C-2.83196 19.8288 -2.83378 38.1693 8.49861 49.5014C19.8291 60.8318 38.1693 60.8335 49.5014 49.5014C60.8319 38.171 60.8337 19.8305 49.5014 8.49848ZM28.9999 53.2304C15.6392 53.2304 4.76935 42.3606 4.76935 28.9999C4.76935 15.6391 15.6392 4.76944 28.9999 4.76944C42.3606 4.76944 53.2304 15.6393 53.2304 29C53.2304 42.3607 42.3607 53.2304 28.9999 53.2304Z" fill={color}/>
        <path d="M32.6288 14.522H24.1613C22.8461 14.522 21.7799 15.5882 21.7799 16.9034V26.6184H19.3229C18.0076 26.6184 16.9414 27.6846 16.9414 28.9998C16.9414 30.3151 18.0076 31.3813 19.3229 31.3813H21.7799V33.8762H19.3229C18.0076 33.8762 16.9414 34.9424 16.9414 36.2576C16.9414 37.5729 18.0076 38.6391 19.3229 38.6391H21.7799V41.0962C21.7799 42.4115 22.8461 43.4777 24.1613 43.4777C25.4766 43.4777 26.5428 42.4115 26.5428 41.0962V38.6391H31.4192C32.7344 38.6391 33.8006 37.5729 33.8006 36.2576C33.8006 34.9424 32.7344 33.8762 31.4192 33.8762H26.5428V31.3813H32.6288C37.2769 31.3813 41.0584 27.5998 41.0584 22.9517C41.0584 18.3035 37.277 14.522 32.6288 14.522ZM32.6288 26.6182H26.5428V19.2848H32.6288C34.6506 19.2848 36.2955 20.9296 36.2955 22.9515C36.2955 24.9733 34.6507 26.6182 32.6288 26.6182Z" fill={color}/>
      </g>
      <defs>
        <clipPath id="clip0_8692_112853">
          <rect width="58" height="58" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default RubleIcon
