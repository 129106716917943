import React, {useEffect, useState} from "react"
import styles from './App.module.sass'
import { Routes, Route } from 'react-router-dom'
import AppContext from "./context/AppContext"
import MobileMenu from "./components/MobileMenu/MobileMenu"
import MainPage from "./pages/MainPage"
import { ROUTES } from "./utils/ROUTES"
import { useTranslation } from "react-i18next"
import AuthPage from "./pages/AuthPage"

function App() {
  const [isBurgerActive, setIsBurgerActive] = useState(false)
  const [language, setLanguage] = useState('ru')

  const { t, i18n } = useTranslation()

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }

  const ctxValue = {
    isBurgerActive,
    setIsBurgerActive,
    language,
    setLanguage,
    t,
    changeLanguage,
  }

  const { MAIN, LOGIN, REGISTER } = ROUTES

  useEffect(() => {
    changeLanguage(language)
  }, [language])

  return (
    <AppContext.Provider value={ctxValue}>
      <div className={styles.app}>
        <Routes>
          <Route path={MAIN} element={<MainPage />} />
          <Route path={REGISTER} element={<AuthPage register />} />
          <Route path={LOGIN} element={<AuthPage />} />
        </Routes>

        <MobileMenu />
      </div>
    </AppContext.Provider>
  )
}

export default App
