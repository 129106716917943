import React, { useContext } from 'react'
import styles from './Navigation.module.sass'
import { motion } from "framer-motion"
import { Link } from 'react-scroll'
import { NAVIGATION } from "../../utils/NAVIGATION"
import AppContext from "../../context/AppContext"

function Navigation({ header }) {
  const { ABOUT, ADVANTAGES, FEATURES, PARTNER, FAQ, TARIFS } = NAVIGATION
  const { setIsBurgerActive, t } = useContext(AppContext)

  const handleLinkClick = () => setIsBurgerActive(false)

  const list = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.15,
      },
    },
  }

  const item = {
    hidden: { opacity: 0, y: -30 },
    visible: { opacity: 1, y: 0 },
  }

  const classes = {
    nav: header
      ? `${styles.navigation} ${styles.navigation_pos_header}`
      : styles.navigation
  }

  const linkConfig = {
    smooth: true,
    duration: 500
  }

  return (
    <nav className={classes.nav}>
      <motion.ul
        variants={list}
        initial="hidden"
        whileInView="visible"
        className={styles.list}>
        <motion.li
          variants={item}
          className={styles.listItem}>
          <Link
            onClick={handleLinkClick}
            to={ABOUT}
            { ...linkConfig }
          >
            { t('nav.about') }
          </Link>
        </motion.li>
        <motion.li
          variants={item}
          className={styles.listItem}>
          <Link
            onClick={handleLinkClick}
            to={ADVANTAGES}
            { ...linkConfig }
          >
            { t('nav.advantages') }
          </Link>
        </motion.li>
        <motion.li
          variants={item}
          className={styles.listItem}>
          <Link
            onClick={handleLinkClick}
            to={FEATURES}
            { ...linkConfig }
          >
            { t('nav.functional') }
          </Link>
        </motion.li>
        <motion.li
          variants={item}
          className={styles.listItem}>
          <Link
            onClick={handleLinkClick}
            to={PARTNER}
            { ...linkConfig }
          >
            { t('nav.partner') }
          </Link>
        </motion.li>
        <motion.li
          variants={item}
          className={styles.listItem}>
          <Link
            onClick={handleLinkClick}
            to={FAQ}
            { ...linkConfig }
          >
            { t('nav.faq') }
          </Link>
        </motion.li>
        <motion.li
          variants={item}
          className={styles.listItem}>
          <Link
            onClick={handleLinkClick}
            to={TARIFS}
            { ...linkConfig }
          >
            { t('nav.tarifs') }
          </Link>
        </motion.li>
      </motion.ul>
    </nav>
  )
}

export default Navigation
