import React, { useContext } from 'react'
import styles from './TarifCard.module.sass'
import ButtonCyanOutline from '../../Button/ButtonCyanOutline'
import { motion } from "framer-motion"
import AppContext from "../../../context/AppContext"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "../../../utils/ROUTES"

function TarifCard({ title, goodsQuantity, text, price }) {
  const { REGISTER } = ROUTES

  const navigate = useNavigate()
  const handleButtonClick = () => navigate(REGISTER)

  const { t } = useContext(AppContext)

  const item = {
    hidden: {
      y: 65,
      opacity: 0
    },
    show: {
      y: 0,
      opacity: 1,
    }
  }

  return (
    <motion.li
      variants={item}
      className={styles.card}>
      <div className={styles.top}>
        <h3 className={styles.title}>{ title }</h3>
        <p className={styles.goods}>{t('tariffs.free.goods.before')} {goodsQuantity} {t('tariffs.free.goods.after')}</p>
        <p className={styles.text}>{ text }</p>
      </div>

      <div className={styles.bottom}>
        <p className={styles.price}>
          <span>{ price }</span>
          { t('tariffs.free.month') }
        </p>

        <div className={styles.buttonContainer}>
          <ButtonCyanOutline click={handleButtonClick} text={t('buttons.tarif')} />
        </div>

      </div>
    </motion.li>
  )
}

export default TarifCard
