import React from 'react'
import styles from './SimpleInput.module.sass'

function SimpleInput({ type, name, placeholder }) {
  return (
    <input
      className={styles.input}
      type={type}
      name={name}
      placeholder={placeholder}
      autoComplete={type === 'password' ? 'off' : 'on' } />
  )
}

export default SimpleInput
