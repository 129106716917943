import React from 'react'
import styles from './SectionTitle.module.sass'
import { motion } from "framer-motion"

function SectionTitle({ title, margin = 0 }) {
  const variants = {
    hide: {
      opacity: 0,
      y: 100
    },

    show: {
      opacity: 1,
      y: 0
    }
  }

  return (
    <motion.h2
      variants={variants}
      initial="hide"
      whileInView="show"
      transition={{ duration: 0.3, delay: 0.2 }}
      className={styles.title} style={{ margin }}>{ title }</motion.h2>
  )
}

export default SectionTitle
