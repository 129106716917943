import React, { useContext } from 'react'
import styles from './FirstScreenSection.module.sass'
import Waves from "../../Waves/Waves"

import dollar from '../../../images/first-screen/dollar.png'
import plusSmall from '../../../images/first-screen/plus-small.png'
import chatImg from '../../../images/first-screen/chat.png'
import heart from '../../../images/first-screen/heart.png'
import arrow from '../../../images/first-screen/arrow.png'
import plusBig from '../../../images/first-screen/plus-big.png'
import cart from '../../../images/first-screen/cart.png'
import ruble from '../../../images/first-screen/ruble.png'
import AppContext from "../../../context/AppContext"

function FirstScreenSection({ children, isArrow }) {
  const { language } = useContext(AppContext)

  return (
    <section className={styles.section}>

        { children }

      <img className={styles.dollar}
           src={language === 'ru' ? ruble : dollar}
           alt={language === 'ru' ? 'Ruble' : 'Dollar'}/>

      <img className={styles.plusSmall} src={plusSmall} alt="plus Small"/>
      <img className={styles.chatLeft} src={chatImg} alt="Chat"/>
      <img className={styles.heartTopTitle} src={heart} alt="Heart"/>
      { isArrow && <img className={styles.arrow} src={arrow} alt="Arrow"/> }
      <img className={styles.plusBig} src={plusBig} alt="Plus"/>
      <img className={styles.dollarSmall}
           src={language === 'ru' ? ruble : dollar}
           alt={language === 'ru' ? 'Ruble' : 'Dollar'}/>

      <img className={styles.plusRight} src={plusSmall} alt="Plus"/>
      <img className={styles.heartRight} src={heart} alt="Heart"/>
      <img className={styles.cart} src={cart} alt="Cart"/>
      <img className={styles.ruble}
           src={language === 'ru' ? ruble : dollar}
           alt={language === 'ru' ? 'Ruble' : 'Dollar'}/>

      <Waves />
    </section>
  )
}

export default FirstScreenSection
