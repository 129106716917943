import React, { useContext } from 'react'
import styles from './NeedOnlineShopSection.module.sass'
import girl from '../../../images/need-online-shop/girl.png'
import man from '../../../images/need-online-shop/man.png'
import woman from '../../../images/need-online-shop/women.png'
import SectionTitle from "../../SectionTitle/SectionTitile"
import { motion } from "framer-motion"
import { NAVIGATION } from "../../../utils/NAVIGATION"
import AppContext from "../../../context/AppContext"

function NeedOnlineShopSection() {
  const { ADVANTAGES } = NAVIGATION
  const { t } = useContext(AppContext)

  const balloonAnim = (from, fromValue, to, toValue) => {
    return {
      hide: {
        opacity: 0,
        [from]: fromValue,
      },

      show: {
        opacity: 1,
        [to]: toValue,
      }
    }
  }

  const textAnim = (from, fromValue, to, toValue, delay) => {
    return {
      hide: {
        opacity: 0,
        [from]: fromValue,
      },

      show: {
        opacity: 1,
        [to]: toValue,
        transition: { duration: 0.1, delay }
      },
    }
  }

  const imgAnim = (from, fromValue, to, toValue, delay) => {
    return {
      hide: {
        opacity: 0,
        [from]: fromValue,
      },

      show: {
        opacity: 1,
        [to]: toValue,
        transition: { duration: 0.2, delay }
      },
    }
  }

  return (
    <section id={ADVANTAGES} className={styles.section}>
      <div className="container_inner">
        <SectionTitle title={t('sectionTitles.needOnlineShop')} margin={'0 0 124px'} />
        <div className={styles.infoContainer}>
          <div className={styles.blur}/>
          <div className={`${styles.card} ${styles.card_first}`}>
            <h3 className={styles.cardTitle}>{ t('needOnlineShopSection.cardOne.title') }</h3>

            <div className={styles.paragraphs}>
              <motion.span
                variants={textAnim(
                  'x',
                  -50,
                  'x',
                  0,
                  0.3
                )}
                initial="hide"
                whileInView="show"
                className={styles.cardText}>{ t('needOnlineShopSection.cardOne.textOne') }</motion.span>

              <motion.span
                variants={textAnim(
                  'x',
                  -50,
                  'x',
                  0,
                  0.4
                )}
                initial="hide"
                whileInView="show"
                className={styles.cardText}>{ t('needOnlineShopSection.cardOne.textTwo') }</motion.span>

              <motion.span
                variants={textAnim(
                  'x',
                  -50,
                  'x',
                  0,
                  0.5
                )}
                initial="hide"
                whileInView="show"
                className={`${styles.cardText} ${styles.cardText_last}`}>{ t('needOnlineShopSection.cardOne.textThree') } <span>&#128577;</span> </motion.span>
            </div>

            <img className={`${styles.cardImg} ${styles.girl}`} src={girl} alt="Girl"/>
          </div>

          <div className={`${styles.card} ${styles.card_mid}`}>
            <h3 className={styles.cardTitle}>{ t('needOnlineShopSection.cardTwo.title') }</h3>
            <div className={styles.paragraphs}>
              <motion.span
                variants={textAnim(
                  'y',
                  50,
                  'y',
                  0,
                  0.4
                )}
                initial="hide"
                whileInView="show"
                className={styles.cardText}>{ t('needOnlineShopSection.cardTwo.textOne') }</motion.span>

              <motion.span
                variants={textAnim(
                  'y',
                  50,
                  'y',
                  0,
                  0.5
                )}
                initial="hide"
                whileInView="show"
                className={styles.cardText}>{ t('needOnlineShopSection.cardTwo.textTwo') } <span>&#128532;</span> </motion.span>

              <motion.span
                variants={textAnim(
                  'y',
                  50,
                  'y',
                  0,
                  0.6
                )}
                initial="hide"
                whileInView="show"
                className={`${styles.cardText} ${styles.cardText_last}`}>{ t('needOnlineShopSection.cardTwo.textThree') } <span>&#128577;</span>  </motion.span>
            </div>
            <img className={`${styles.cardImg} ${styles.man}`} src={man} alt="Man"/>
          </div>

          <div className={`${styles.card} ${styles.card_last}`}>
            <h3 className={`${styles.cardTitle} ${styles.cardTitle_last}`}>
              <span>{ t('needOnlineShopSection.cardThree.title.partOne') + ' ' }</span>
              <span>{ t('needOnlineShopSection.cardThree.title.partTwo') }</span>
            </h3>

            <div className={styles.paragraphs}>
              <motion.span
                variants={textAnim(
                  'x',
                  50,
                  'x',
                  0,
                  0.5
                )}
                initial="hide"
                whileInView="show"
                className={styles.cardText}>{ t('needOnlineShopSection.cardThree.textOne') }</motion.span>

              <motion.span
                variants={textAnim(
                  'x',
                  50,
                  'x',
                  0,
                  0.6
                )}
                initial="hide"
                whileInView="show"
                className={styles.cardText}>{ t('needOnlineShopSection.cardThree.textTwo') } <span>&#128532;</span> </motion.span>

              <motion.span
                variants={textAnim(
                  'x',
                  50,
                  'x',
                  0,
                  0.7
                )}
                initial="hide"
                whileInView="show"
                className={`${styles.cardText} ${styles.cardText_last}`}>{ t('needOnlineShopSection.cardThree.textThree') }</motion.span>
            </div>
            <img className={`${styles.cardImg} ${styles.woman}`} src={woman} alt="Woman"/>
          </div>
        </div>
      </div>


      <motion.div
        variants={balloonAnim(
          'x',
          -100,
          'x',
          0
        )}
        initial="hide"
        whileInView="show"
        transition={{ duration: 0.6, delay: 0.3 }}
        className={styles.balloonBig}/>

      <motion.div
        variants={balloonAnim(
          'x',
          -100,
          'x',
          0
        )}
        initial="hide"
        whileInView="show"
        transition={{ duration: 0.3, delay: 0.3 }}
        className={styles.balloonMob}/>

      <motion.div
        variants={balloonAnim(
          'y',
          80,
          'y',
          0
        )}
        initial="hide"
        whileInView="show"
        transition={{ duration: 0.7, delay: 0.5 }}
        className={styles.balloonSmall}/>


      <motion.div
        variants={balloonAnim(
          'x',
          140,
          'x',
          0
        )}
        initial="hide"
        whileInView="show"
        transition={{ duration: 0.7, delay: 0.8 }}
        className={styles.balloonMedium}/>
    </section>
  )
}

export default NeedOnlineShopSection
