import React from 'react'
import styles from './ServerAdvantage.module.sass'
import { motion } from "framer-motion"

function ServerAdvantage({ text }) {
  const variants = {
    hidden: {
      x: 65,
      opacity: 0
    },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
      }
    }
  }

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      whileInView="show"
      className={styles.container}>
      <div className={styles.arrow} />
      <span className={styles.text}>{ text }</span>
    </motion.div>
  )
}

export default ServerAdvantage
