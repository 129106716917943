import React from 'react'
import RegisterForm from "../components/forms/RegisterForm"
import LoginForm from "../components/forms/LoginForm"

function AuthPage({ register }) {
  return (
    <>
      { register ? <RegisterForm /> : <LoginForm /> }
    </>
  )
}

export default AuthPage
